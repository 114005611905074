import { Grid, makeStyles, Typography, Paper, TextField, Button } from '@material-ui/core'
import React, {useState} from 'react'
import Amplify, {API} from 'aws-amplify'
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
          margin: theme.spacing(1),
          width: '90%',
        },
        '& .MuiSelect-root': {
            margin: theme.spacing(0),
            width: '28ch',
          },
        '& > *': {
            margin: theme.spacing(1),
          },
    },
    input: {
        display: 'none',
      },
    gridContentBox: {
        backgroundColor: '#fff',
        padding: theme.spacing(3),
    },
    gridContent: {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.info.dark,
        color: '#fff'
    },
    iconButton: {
        color: '#fff'
    },
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '2vh',
        color: '#fff'
        
    },
    buttonLabel:{
        fontSize: '2vh',
        color: '#fff'
    },
    logoCardMedia: {
        height: theme.spacing(20)
    },
    gridHero:{

        [theme.breakpoints.up('xs')]: {
            textAlign: "right",
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: "center",
        },

    },
    phoneIcon:{
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(25),
        },

    },
    avatar:{
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(20),
            height: theme.spacing(20),
            fontSize: theme.spacing(10),
        },
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            fontSize: theme.spacing(5),
        },
        backgroundColor: theme.palette.info.light,
        
    },
    iconButtonLabel2: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '2vh',
        
    },
    buttonLabel2:{
        fontSize: '2vh',
    },
    dsphelperFont:{

            fontWeight: '100',
            fontStyle:'oblique',
      
    }

  }))


function LandingPage() {
    const classes = useStyles()
    const [body, setBody] = useState({})
    const [nameErrorMessage, setNameErrorMessage] = useState()
    const [nameError, setNameError] = useState(false)
    const [emailErrorMessage, setEmailErrorMessage] = useState()
    const [emailError, setEmailError] = useState(false)
    const [sumbitDone, setSubmitDone] = useState(false)


    const isValid = () => {
        let hasError = false

        if(!body.name){
            hasError = true
            setNameError(true)
            setNameErrorMessage('Please provide your name')
            console.log('Name is blank')
        }

        if(!body.email){
            hasError = true
            setEmailError(true)
            setEmailErrorMessage('Please provide your email address')
            console.log('Email is blank')
        }

        return !hasError

    }

    

    const handleTextfieldChange = (thisBody) => {
        if(thisBody.target.name === 'name' && thisBody.target.value){
            setNameError(false)
            setNameErrorMessage(null)
        }
        
        if(thisBody.target.name === 'email' && thisBody.target.value){
            setEmailError(false)
            setEmailErrorMessage(null)
        }
        setBody({...body, [thisBody.target.name]: thisBody.target.value})
    }
    

    const sendEmail = async () => {
        const myInit = {
            body : body
        }

        if(!isValid()) return

        const response = await API.post('betasignup','/email',myInit)
        setSubmitDone(true)
        console.log('response', response)
    }



    return (
        <>
            <Grid container spacing={5} justify = "center" >
                <Grid item xs={12} md={12}  className={classes.gridContent}  > 
                    <Grid container  spacing={5} justify = "center" ><Grid item  align = "center"  xs={12} > &nbsp;</Grid></Grid>
                    <Grid container  spacing={5} justify = "center" >
                        <Grid item  align = "center"  xs={8} >
                            <img  style={{height: "80px"}} src="./logo192.png" alt="The DSP Helper logo."  />
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} justify = "center" >
                        <Grid item  sm={12} >
                            <Typography variant="h4" className={classes.dsphelperFont}   align = "center">DSP-HELPER</Typography>
                        </Grid>
                    </Grid>
                    <Grid container  spacing={5} justify = "center" ><Grid item  align = "center"  xs={12} > &nbsp;</Grid></Grid>

                    <Grid container spacing={5}  >
                        <Grid item xs={12} md={6} className={classes.gridHero} >
                            <img className={classes.phoneIcon} src="./images/PhoneApp.png" alt="The app on a Phone" />
                        </Grid>
                        <Grid item xs={12} md={6} align = "center" >
                            

                                <Grid container spacing={5}  >
                                    <Grid item xs={12}>
                                        <Grid container  >
                                            <Grid item xs={1} align = "right" ></Grid>
                                            <Grid item xs={10} align = "left" >
                                                <Typography variant="h3"  align = "left">Manage Your Fleet and Track Your Assets Through One Simple App</Typography>
                                                <Typography variant="h5"  align = "left">&nbsp;</Typography>
                                                <Typography variant="h5"  align = "left">Save time and money and never miss your scorecard PM again.</Typography>
                                                <Typography variant="h5"  align = "left">&nbsp;</Typography>
                                            </Grid>
                                            <Grid item xs={1} align = "right" ></Grid>
                                        </Grid>

                                        <Grid container  >
                                            <Grid item xs={1} align = "right" ></Grid>
                                            <Grid item xs={10} align = "left" >

                                                <Paper elevation={20} style={{padding: 16}}>

                                                {
                                                    sumbitDone ? (
                                                        <Typography variant="h7"  align = "left"  >Thank you for signing up. We will contact you shortly about the next steps.</Typography>
                                                        
                                                    ) : (
                                                        <>
                                                        <Typography variant="h7"  align = "left"  >DSP-Helper is currently available by invitation only. Please sign up for our waiting list.</Typography>
                                                        <Typography variant="h5"  align = "left">&nbsp;</Typography>
                                                        <form className={classes.root} noValidate autoComplete="off">
                                                            
                                                            <TextField id="name" name="name"  label="Name" variant="outlined" error={nameError} helperText={nameErrorMessage} value={body.name || ''} onChange={handleTextfieldChange} />
                                                            <TextField id="email" name="email" label="Email" variant="outlined" error={emailError} helperText={emailErrorMessage} value={body.email || ''} onChange={handleTextfieldChange}/>
                                                            <TextField id="stationCode" name="stationCode" label="Station Code" variant="outlined" value={body.stationCode || ''} onChange={handleTextfieldChange}/>
                                                            <br/>
                                                            <Button onClick={sendEmail} variant="outlined" color="primary">Submit</Button>
                                                        </form>
                                                        </>

                                                    )
                                                }    
                                                    

                                                </Paper>
                                                
                                            </Grid>
                                            <Grid item xs={1} align = "right" ></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default LandingPage